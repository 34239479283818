"use strict";

const link = document.querySelector(".c-header__menu-trigger");
const nextElement = document.querySelector(".mm-listitem__text");
const closeMenu = document.querySelector(".btn--menu-close");

// Mmenu basic actions.
document.addEventListener("DOMContentLoaded", () => {
  const menu = new Mmenu("#menu", {
    offCanvas: {
      position: "right-front",
    },
    keyboardNavigation: {
      enable: true,
      enhance: true,
    },
    theme: "black-contrast",
  });

  const api = menu.API;

  // document.querySelector("#open-menu").addEventListener("click", () => {
  //   api.open();
  // });

  // closeMenu.addEventListener("click", () => {
  //   api.close();
  // });
});

window.addEventListener('load', function () {
  if (document.body.classList.contains('mm-wrapper')) {
    var element = document.querySelector('.mm-navbar');
    if (element) {
      element.remove();
    }
  }
});

// Keyboard open menu on enter.
link.addEventListener("keydown", (event) => {
  if (event.key === "Enter") {
    // prevent the default action of the return key
    event.preventDefault();
    // trigger the click event on the link
    link.click();
    // set the tab order of the next element
    nextElement.tabIndex = 0;
    // move focus to the next element
    nextElement.focus();
  }
});


const grid = document.querySelector('.card');
if (grid) {
  let msnry;

  imagesLoaded(grid, function () {
    // init Masonry after all images have loaded
    msnry = new Masonry(grid, {
      itemSelector: '.card__item',
      columnWidth: '.grid-sizer',
      percentPosition: true,
      gutter: 80,
    });

    // Find all lazy-loaded images
    const lazyImages = grid.querySelectorAll('.card img');

    lazyImages.forEach(img => {
      img.addEventListener('load', function () {
        // Layout Masonry when each image loads
        msnry.layout();
      });
    });
  });
}
